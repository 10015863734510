<template>
  <el-card shadow="never">
    <template #header>
      <i class="el-icon-tickets"></i>&nbsp;
      <span>{{ blocktitle }} - 区域内容管理{{areaheight}}</span>
    </template>

    <div class="content">
      <div class="content-header">
        <el-row>
          <el-col>
            <el-button type="success" class="f-right" @click="addCM()"
              >添加区域内容</el-button
            >
          </el-col>
        </el-row>
      </div>

      <div class="content-main">
        <div class="tree-head">
          <div class="tree-head-one">背景图/标题</div>
          <div class="tree-head-two">内容高度(px)</div>
          <div class="tree-head-three">内容列数</div>
          <div class="tree-head-four">链接</div>
          <div class="tree-head-three">状态</div>
          <div class="tree-head-control">操作</div>
        </div>
        <el-tree
          :data="contentarr"
          :props="treeDefaultProps"
          :default-expand-all="true"
          :expand-on-click-node="false"
        >
          <template #default="{ node, data }">
            <div class="tree-custom-node">
              <span class="cate_name">
                <span v-if="!data.column" class="thumb">
                  <el-image
                    v-if="data.src"
                    class="cate-thumb"
                    :src="data.src"
                  /> </span
                >{{ data.title }}</span
              >
              <span class="tree-head-two" show-overflow-tooltip>{{
                data.height
              }}</span>
              <span class="tree-head-three">{{ data.column }}</span>
              <span class="tree-head-four">{{ data.linkname }}</span>
              <span class="tree-head-three">
                <label v-if="data.status * 1 == 1" style="color: green"
                  >已启用</label
                >
                <label v-else-if="data.status * 1 == 0" style="color: red"
                  >已停用</label
                >
              </span>

              <span v-if="data.column" class="cate_control">
                <el-button type="text" @click.stop="addItem('root', node, data)"
                  >添加子项</el-button
                >
                <el-button
                  type="text"
                  @click.stop="cmUpdateStatus('root', node, data)"
                  :class="data.status * 1 == 1 ? 'delete' : ''"
                  >{{ data.status * 1 == 0 ? "启用" : "停用" }}</el-button
                >
                <el-button type="text" @click.stop="addCM(node, data)"
                  >编辑</el-button
                >
                <el-button type="text" @click="cmMoveUp('root', node, data)"
                  >上移</el-button
                >
                <el-button type="text" @click="cmMoveDown('root', node, data)"
                  >下移</el-button
                >
                <el-button
                  class="delete"
                  type="text"
                  @click.stop="delCM(node, data)"
                  >删除</el-button
                >
              </span>

              <span v-else class="cate_control">
                <el-button
                  v-if="data.linktype == 'goodslist'"
                  type="text"
                  @click.stop="selGoods(node, data)"
                  >商品管理</el-button
                >
                <el-button
                  type="text"
                  :class="data.status * 1 == 1 ? 'delete' : ''"
                  @click.stop="cmUpdateStatus('children', node, data)"
                  >{{ data.status * 1 == 0 ? "启用" : "停用" }}</el-button
                >
                <el-button
                  type="text"
                  @click.stop="addItem('children', node, data)"
                  >编辑</el-button
                >
                <el-button type="text" @click="cmMoveUp('children', node, data)"
                  >上移</el-button
                >
                <el-button
                  type="text"
                  @click="cmMoveDown('children', node, data)"
                  >下移</el-button
                >
                <el-button
                  class="delete"
                  type="text"
                  @click.stop="delItem(node, data)"
                  >删除</el-button
                >
              </span>
            </div>
          </template>
        </el-tree>
      </div>

      <!--<el-button type="primary" @click="saveContents()">保 存</el-button>-->
      <el-button @click="goBack()">返 回</el-button>
    </div>
  </el-card>

  <el-dialog
    title="区域内容编辑器"
    v-model="editblock"
    width="40%"
    @close="closeBlock"
  >
    <el-form
      :model="curblock"
      label-width="100px"
      class="dataform"
      :rules="rules.cm"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curblock.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="内容高度" prop="height">
        <el-input-number
          v-model="curblock.height"
          controls-position="right"
          :min="1"
        ></el-input-number>
        px
        <el-tag size="small" type="danger">每行内容的高度</el-tag>
      </el-form-item>
      <el-form-item label="内容列数" prop="column">
        <el-input-number
          v-model="curblock.column"
          controls-position="right"
          :min="1"
          :max="5"
        ></el-input-number>
        px
        <el-tag size="small" type="danger"
          >每行显示的内容项数量，最多5列</el-tag
        >
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editblock = false">取 消</el-button>
        <el-button type="primary" @click="saveBlock()">保存设置</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-if="edititem"
    title="内容项编辑器"
    v-model="edititem"
    width="40%"
    @close="closeItem"
  >
    <el-form
      :model="curitem"
      label-width="100px"
      class="dataform"
      ref="dataform"
      :rules="rules.item"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curitem.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="背景图" prop="img">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="bgimageUploadSuccess"
          :on-change="bgimageUploadChange"
          :on-error="bgimgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curitem.src"
            :src="curitem.src"
            class="avatar"
            :preview-src-list="[curitem.src]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >背景图高度请保持与设定的内容高度一致</el-tag
        >
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curitem.linktype" @change="changeItemLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="
            curitem.linktype &&
            curitem.linktype != 'notlink' &&
            curitem.linktype != 'goodslist' &&
            curitem.linktype != 'link'
          "
          class="tab-header-contorl"
        >
          <el-input
            v-model="curitem.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="itemSelLink" class="sel-btn"
            >选择</el-button
          >
        </div>
        <div v-else-if="curitem.linktype == 'link'" class="tab-header-contorl">
          <el-input
            v-model="curitem.link"
            autocomplete="off"
            class="readinput"
            placeholder="请输入链接地址"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="edititem = false">取 消</el-button>
        <el-button type="primary" @click="saveItem">保存内容项</el-button>
      </span>
    </template>
  </el-dialog>

  <LinkBlock
    :v-if="itemshowlink"
    :linktype="itemlinktype"
    :showlink="itemshowlink"
    :curgoodsid="itemgoodsid"
    :curlink="itemlink"
    v-on:close="onClose"
    v-on:submit="setLink"
  ></LinkBlock>
</template>

<script>
import pageconst from "@/pageconst";
import { ElMessage, ElMessageBox } from "element-plus";
import LinkBlock from "@/components/LinkBlock";

export default {
  data() {
    return {
      uploadAction: pageconst.upload_pdt_detail_url,
      treeDefaultProps: {
        children: "items",
        label: "title",
      },
      gmShowlink: false,
      query: {},
      pagename: "",
      type: "",
      config: {},
      contentarr: [],
      blocktitle: "",
      editblock: false,
      orgblock: "",
      curblock: {},
      blockindex: "",
      rules: {
        cm: {
          title: [
            { required: true, message: "请输入区域内容标题", trigger: "blur" },
          ],
        },
        item: {
          title: [
            { required: true, message: "请输入内容项标题", trigger: "blur" },
          ],
        },
      },
      issubmit: false,
      linktype: [
        {
          value: "notlink",
          label: "不跳转",
        },
        {
          value: "page",
          label: "页面跳转",
        },
        {
          value: "goods",
          label: "商品",
        },
        {
          value: "goodslist",
          label: "商品集合页",
        },
        {
          value: "link",
          label: "链接",
        },
      ],
      curitem: {},
      orgitem: "",
      itemindex: "",
      edititem: false,
      itemlinktype: "",
      itemshowlink: false,
      itemgoodsid: "",
      itemlink: "",
      areaheight: "",
    };
  },

  components: {
    LinkBlock,
  },

  methods: {
    goBack() {
      history.go(-1);
    },

    getNextID() {
      let maxid = 0;
      for (let i = 0; i < this.contentarr.length; i++) {
        if (maxid < this.contentarr[i].id * 1) {
          maxid = this.contentarr[i].id * 1;
          let curchildren = this.contentarr[i].items;
          for (let j = 0; j < curchildren.length; j++) {
            if (maxid < curchildren[j].id * 1) {
              maxid = curchildren[j].id * 1;
            }
          }
        }
      }
      return maxid + 1;
    },

    getCurIndex(node, data) {
      if (!node || !data) return "";

      let parent = node.parent;
      let children = parent.data.items || parent.data;
      let index = children.findIndex((d) => d.id === data.id);

      return index;
    },

    saveContents() {
      let that = this,
        { type, index } = this.query,
        config = this.config;

      if (this.pagename == "index") {
        config[type].value[index].value = this.contentarr;
      } else if (this.pagename.indexOf("compage_") !== -1) {
        config.blocks[index].items = this.contentarr;
      }

      this.axios
        .post(
          pageconst.pagemanage + "?entry=saveconfig&pagename=" + this.pagename,
          JSON.stringify(config),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(({ data }) => {
          if (data == "SUCCESS" || (that.pagename.indexOf("compage_") !== -1 && data.indexOf("SUCCESS|compage_") !== -1)) {
            /*ElMessage({
              showClose: true,
              message: "保存成功",
              type: "success",
              onClose: function () {
                //history.go(-1);
              },
            });*/
            console.log("保存成功");
          } else {
            ElMessage.error({
              showClose: true,
              message: data,
            });
            return false;
          }
        });
    },

    closeBlock() {
      this.editblock = false;
      this.issubmit = false;
      if (this.orgblock !== "") {
        this.contentarr[this.blockindex] = JSON.parse(this.orgblock);
      }
    },

    addCM(node = "", data = "") {
      let index = this.getCurIndex(node, data);

      if (index !== "") {
        this.blockindex = index;
        this.curblock = this.contentarr[index];

        if (this.curblock.height)
          this.curblock.height = this.curblock.height * 1;
        if (this.curblock.column)
          this.curblock.column = this.curblock.column * 1;

        this.orgblock = JSON.stringify(this.curblock);
      } else {
        this.blockindex = "";
        this.curblock = {};
        this.curblock.id = this.getNextID();
        this.curblock.column = 1;
        this.curblock.height = 50;
        this.curblock.items = [];
        this.curblock.status = 1;
        this.orgblock = "";
      }

      this.editblock = true;
    },

    delCM(node, data) {
      let index = this.getCurIndex(node, data);

      if (!this.contentarr[index]) return false;

      ElMessageBox.confirm(
        "您确定要删除 " + this.contentarr[index].title + " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.contentarr.splice(index, 1);
          this.saveContents();
        })
        .catch(() => {
          console.log("取消操作");
        });
    },

    cmSwapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    cmMoveUp(type, node, data) {
      let index = this.getCurIndex(node, data);

      if (type == "root") {
        if (index != 0) {
          this.contentarr = [
            ...this.cmSwapArray(this.contentarr, index, index - 1),
          ];
        }
      } else if (type == "children") {
        let rootindex = this.getCurIndex(node.parent, node.parent.data);

        if (index != 0) {
          let carr = this.contentarr;
          carr[rootindex].items = this.cmSwapArray(
            carr[rootindex].items,
            index,
            index - 1
          );
          this.contentarr = [...carr];
        }
      }

      this.saveContents();
    },
    cmMoveDown(type, node, data) {
      let index = this.getCurIndex(node, data);

      if (type == "root") {
        if (index + 1 != this.contentarr.length) {
          this.contentarr = [
            ...this.cmSwapArray(this.contentarr, index, index + 1),
          ];
        }
      } else if (type == "children") {
        let rootindex = this.getCurIndex(node.parent, node.parent.data);

        let carr = this.contentarr;
        carr[rootindex].items = this.cmSwapArray(
          carr[rootindex].items,
          index,
          index + 1
        );
        this.contentarr = [...carr];
      }

      this.saveContents();
    },

    cmUpdateStatus(type, node, data) {
      let index = this.getCurIndex(node, data);

      if (type == "root") {
        if (!this.contentarr[index]) return false;

        if (this.contentarr[index].status == 1) {
          ElMessageBox.confirm(
            "您确定要下架 " + this.contentarr[index].title + " 吗？",
            "系统提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.contentarr[index].status = 0;
              this.saveContents();
            })
            .catch(() => {
              console.log("取消操作");
            });
        } else {
          this.contentarr[index].status = 1;
          this.saveContents();
        }
      } else if (type == "children") {
        let rootindex = this.getCurIndex(node.parent, node.parent.data);

        if (!this.contentarr[rootindex].items[index]) return false;

        if (this.contentarr[rootindex].items[index].status == 1) {
          ElMessageBox.confirm(
            "您确定要下架 " +
              this.contentarr[rootindex].items[index].title +
              " 吗？",
            "系统提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.contentarr[rootindex].items[index].status = 0;
              this.saveContents();
            })
            .catch(() => {
              console.log("取消操作");
            });
        } else {
          this.contentarr[rootindex].items[index].status = 1;
          this.saveContents();
        }
      }
    },

    saveBlock() {
      if (this.issubmit) {
        return false;
      }

      this.$refs["dataform"].validate((res) => {
        if (res) {
          this.issubmit = true;
          if (this.blockindex !== "") {
            this.orgblock = "";
            this.contentarr[this.blockindex] = this.curblock;
          } else {
            this.contentarr.push(this.curblock);
          }

          this.editblock = false;

          this.saveContents();
        }
      });
    },

    addItem(type, node = "", data = "") {
      this.issubmit = false;
      if (type == "children" && node && data) {
        let index = this.getCurIndex(node, data);
        let rootindex = this.getCurIndex(node.parent, node.parent.data);

        if (
          !this.contentarr[rootindex] ||
          !this.contentarr[rootindex].items[index]
        )
          return false;

        this.blockindex = rootindex;
        this.itemindex = index;
        this.curitem = this.contentarr[rootindex].items[index];

        if (!this.curitem.linktype) {
          this.curitem.linktype = "notlink";
          this.curitem.linkname = "不跳转";
        }

        this.orgblock = JSON.stringify(this.curblock);
      } else {
        if (type == "root") {
          this.blockindex = this.getCurIndex(node, data);
        }

        this.itemindex = "";
        this.curitem = {};
        this.curitem.id = this.getNextID();
        this.curitem.linktype = "notlink";
        this.curitem.link = "";
        this.curitem.linkname = "不跳转";
        this.curitem.title = "";
        this.curitem.src = "";
        this.curitem.status = 0;
        this.orgitem = "";
      }

      this.edititem = true;
    },

    closeItem() {
      this.edititem = false;
      this.issubmit = false;
      if (this.orgitem !== "") {
        this.contentarr[this.blockindex].items[this.itemindex] = JSON.parse(
          this.orgitem
        );
      }
    },

    saveItem() {
      if (this.issubmit) {
        return false;
      }

      this.$refs["dataform"].validate((res) => {
        if (res) {
          this.issubmit = true;
          if (this.blockindex !== "" && this.itemindex !== "") {
            this.orgitem = "";
            this.contentarr[this.blockindex].items[this.itemindex] =
              this.curitem;
          } else {
            this.contentarr[this.blockindex].items.push(this.curitem);
          }

          this.contentarr = [...this.contentarr];

          this.edititem = false;

          this.saveContents();
        }
      });
    },

    delItem(node, data) {
      let index = this.getCurIndex(node, data);
      let cmindex = this.getCurIndex(node.parent, node.parent.data);

      if (!this.contentarr[cmindex].items[index]) return false;

      ElMessageBox.confirm(
        "您确定要删除 " + this.contentarr[cmindex].items[index].title + " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.contentarr[cmindex].items.splice(index, 1);
          this.contentarr = [...this.contentarr];
          this.saveContents();
        })
        .catch(() => {
          console.log("取消操作");
        });
    },

    selGoods(node, data) {
      let { type, index } = this.query;

      let itemindex = this.getCurIndex(node, data);
      let cmindex = this.getCurIndex(node.parent, node.parent.data);

      this.$router.push({
        path: "/page/goodsmanage",
        query: {
          pagename: this.pagename,
          type: type,
          index: index,
          cmindex: cmindex,
          itemindex: itemindex,
        },
      });
    },

    bgimageUploadChange(file) {
      let isSubmit = file.raw.size / 1024 / 1024 < 2;
      if (!isSubmit) {
        this.$message.error("图片大小不能超过 2MB!");
        return false;
      }

      if (isSubmit) this.$refs.bgimageUpload.submit();
    },
    bgimageUploadSuccess(res) {
      console.log("image upload success ===> " + res.url);
      if (!this.editblock) {
        // 区域背景
        this.formdata.bgimage = res.url;
      } else {
        this.curitem.src = res.url;
      }
    },
    bgimgUploadError(err) {
      console.log("err==>");
      console.log(err);
    },

    onClose(isclose) {
      this.itemshowlink = isclose;
    },
    setLink(link, linkname, goodsid = "") {
      console.log(link, linkname, goodsid);
      this.curitem.link = link;
      this.curitem.linkname = linkname;
      this.curitem.goodsid = goodsid;
    },
    itemSelLink() {
      this.itemlinktype = this.curitem.linktype;
      this.itemgoodsid = this.curitem.goodsid;
      this.itemlink = this.curitem.link;
      this.itemshowlink = true;
    },
    changeItemLinkType(e) {
      this.itemlinktype = e;
      this.link = "";
      if (e != "notlink" && e != "goodslist" && e != "link") {
        this.curitem.linkname = "";
        this.itemshowlink = true;
      } else if (e == "goodslist") {
        this.curitem.linkname = "商品集合页";
      } else if (e == "link") {
        this.curitem.linkname = "自定义链接";
      } else {
        this.curitem.linkname = "不跳转";
      }
    },
  },

  created() {
    this.query = this.$route.query;

    let { pagename, maintype, type, index } = this.$route.query;
    this.pagename = pagename;

    console.log(pagename, maintype, type, index);

    this.axios
      .get(pageconst.pagemanage + "?entry=getconfig&pagename=" + pagename, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => {
        let { config } = data,
          contentarr = [];
        this.config = config;
        console.log("content_manage pageconfig => ", config);

        if (pagename == "index") {
          let blocktitle = "首页 - " +
            (type == "venue" ? "主场活动" : type == "special" ? "专区" : "");
          blocktitle += " - " + config[type].value[index].title;
          this.blocktitle = blocktitle;

          this.areaheight = "（区域高度：" + (config[type].value[index].height > 0 ? config[type].value[index].height + 'px' : '自动高度') + "）";

          contentarr = config[type].value[index].value
            ? config[type].value[index].value
            : [];
        } else if (pagename.indexOf("compage_") !== -1) {
          let blocktitle = "活动页 - " + config.title;
          blocktitle += " - " + config.blocks[index].title;
          this.blocktitle = blocktitle;

          this.areaheight = "（区域高度：" + (config.blocks[index].lineheight > 0 ? config.blocks[index].lineheight + 'px' : '自动高度') + "）";

          contentarr = config.blocks[index].items
            ? config.blocks[index].items
            : [];
        }

        this.contentarr = contentarr;
      });
  },
};
</script>


<style scoped>
.content-main {
  margin: 20px 0;
}

.tab-header-contorl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.sel-btn {
  margin-left: 10px;
}

.dataform >>> .avatar-uploader {
  line-height: 0;
}

.dataform >>> .avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.dataform >>> .avatar {
  width: 100%;
  max-width: 400px;
  display: block;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

.cate_control .el-button {
  margin-left: 15px;
}
.cate_control .el-button:first-child {
  margin-left: 0;
}
.cate_control .el-button.delete {
  color: #f66;
}
.cate_control .el-button.green {
  color: #67c23a;
}

.tree-head {
  height: 48px;
  line-height: 48px;
  border: 1px solid #ebeef5;
  border-bottom: none;
  display: flex;
  font-weight: bold;
  color: #909399;
  font-size: 14px;
  margin-top: 20px;
}

.tree-head .tree-head-one {
  padding-left: 8px;
  flex-grow: 1;
}

.tree-head-two {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-head-three {
  width: 100px;
}
.tree-head-four {
  width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.tree-head .tree-head-control,
.el-tree .tree-custom-node .cate_control {
  width: 360px;
  padding-right: 8px;
  text-align: right;
}

.el-tree {
  overflow: hidden;
  border-bottom: 1px solid #ebeef5;
}

>>> .el-tree-node__content {
  height: 48px;
  border: 1px solid #ebeef5;
  border-bottom: 0;
}

.tree-custom-node {
  height: 100%;
  line-height: 48px;
  font-size: 14px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.el-tree .tree-custom-node .cate_name {
  flex-grow: 1;
  height: 48px;
}

.cate_form >>> .input-size {
  width: 50%;
}

.thumb {
  height: 100%;
  padding: 4px 0;
  box-sizing: border-box;
  margin-right: 10px;
  display: block;
  float: left;
}

>>> .cate-thumb {
  height: 100%;
  padding: 2px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 4px;
}

>>> .cate-thumb img {
  width: auto;
}
</style>
